import React from 'react';

import Pattern from '../pattern/pattern.component';
import ContentHeader from './content-header.component';
import ContentDescription from './content-description.component';
import ContentImage from './content-image.component';

const Content = ({ post, title, description, html, coverImage }) => {
  return (
    <div className='relative py-16 bg-white overflow-hidden'>
      <div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
        <div
          className='relative h-full text-lg max-w-prose mx-auto'
          aria-hidden='true'
        >
          <Pattern
            className={'top-12 left-full transform translate-x-32'}
            SVGHeight={384}
            SVGWidth={404}
            patternID={'74b3fd99-0a6f-4271-bef2-e80eeafdf357'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={384}
            rectWidth={404}
          />
          <Pattern
            className={
              'top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
            }
            SVGHeight={384}
            SVGWidth={404}
            patternID={'f210dbf6-a58d-4871-961e-36d5016a0f49'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={384}
            rectWidth={404}
          />
          <Pattern
            className={'bottom-12 left-full transform translate-x-32'}
            SVGHeight={384}
            SVGWidth={404}
            patternID={'d3eb07ae-5182-43e6-857d-35c643af9034'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={384}
            rectWidth={404}
          />
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='text-lg max-w-4xl mx-auto'>
          <ContentHeader postType={post} title={title} />
          <ContentDescription description={description} />
          <ContentImage postCoverImageUrl={coverImage} title={title} />
        </div>
        <div className='mt-6 pt-6 border-t border-blue-gray-200 prose max-w-4xl prose-purple prose-base text-blue-gray-500 mx-auto font-osans center-image'>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </div>
  );
};

export default Content;
