import React from 'react';

const ContentHeader = ({ title, postType }) => {
  return (
    <h1 className='mb-6'>
      <span
        className={`block text-base text-center ${
          postType === 'lecture' ? 'text-fuchsia-700' : 'text-rose-600'
        } font-semibold font-osans tracking-wide uppercase`}
      >
        const - JS | {postType}
      </span>
      <span className='mt-2 block text-3xl text-center leading-8 font-extrabold font-osans tracking-tight text-blue-gray-800 dark:text-white sm:text-4xl'>
        {title}
      </span>
    </h1>
  );
};

export default ContentHeader;
