import React from 'react';
import { Link } from 'gatsby';
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';

const NextPrevious = ({
  post,
  postLinkPrevious,
  postLinkNext,
  postDescriptionPrevious,
  postDescriptionNext,
}) => {
  return (
    <div className='pt-8 pb-16'>
      <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
        <nav className='border-t border-blue-gray-200 px-4 flex items-center justify-between sm:px-0'>
          {postLinkPrevious && (
            <div className='-mt-px w-0 flex-1 flex'>
              <Link
                to={`/${
                  post === 'lecture' ? 'lectures' : 'blogs'
                }/${postLinkPrevious}`}
                className='border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-blue-gray-500 hover:text-blue-gray-700 hover:border-blue-gray-300'
              >
                <ArrowNarrowLeftIcon
                  className='mr-3 h-5 w-5 text-blue-gray-400'
                  aria-hidden='true'
                />
                {postDescriptionPrevious}
              </Link>
            </div>
          )}
          {postLinkNext && (
            <div className='-mt-px w-0 flex-1 flex justify-end'>
              <Link
                to={`/${
                  post === 'lecture' ? 'lectures' : 'blogs'
                }/${postLinkNext}`}
                className='border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-blue-gray-500 hover:text-blue-gray-700 hover:border-blue-gray-300'
              >
                {postDescriptionNext}
                <ArrowNarrowRightIcon
                  className='ml-3 h-5 w-5 text-blue-gray-400'
                  aria-hidden='true'
                />
              </Link>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default NextPrevious;
