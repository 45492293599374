import React from 'react';

const ContentDescription = ({ description }) => {
  return (
    <div className='relative font-semibold text-center'>
      <p className='text-md text-blue-gray-800 font-semibold font-osans sm:inline'>
        {description}
      </p>
    </div>
  );
};

export default ContentDescription;
