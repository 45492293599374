import React from 'react';
import { Image } from 'cloudinary-react';

const ContentImage = ({ postCoverImageUrl, title }) => {
  return (
    <div className='aspect-w-12 aspect-h-7 mt-8'>
      <Image
        className='h-full w-full object-cover absolute rounded-lg shadow-md'
        cloudName='dmcookpro'
        publicId={postCoverImageUrl}
        loading='lazy'
        width='100%'
        height='100%'
        alt={title}
      ></Image>
    </div>
  );
};

export default ContentImage;
